<template>
  <div class="main-box">
    <MainBodyHeader title="پروفایل تخصصی">
      <CRow
        class="w-100"
        style="justify-content: space-between"
        align-vertical="center"
      >
        <h4 class="text-danger">
          در صورت تغییر در مدارک ارسالی و ذخیره تغییرات حساب شما مجدد باید توسط
          مدیر فعال شود.
        </h4>
        <button class="btn-action">ذخیره تغییرات</button>
      </CRow>
    </MainBodyHeader>
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات شما..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CRow class="w-100">
      <CCol col="3">
        <div class="main-card vertical-g-5">
          <div class="file-box number-box mb-2">
            <h4>سابقه کار به ماه</h4>
            <input type="number" placeholder="0" />
            <i class="fa fa-slack"></i>
          </div>
          <div class="file-box btn-secondary">
            <i class="fa fa-upload" aria-hidden="true"></i>
            <label for="file-upload" class="custom-file-upload">
              تصویر کارت ملی برای مدیر
            </label>
            <input id="file-upload" type="file" />
          </div>
          <div class="file-box btn-secondary">
            <i class="fa fa-upload" aria-hidden="true"></i>
            <label for="file-upload" class="custom-file-upload">
              تصویر شناسنامه برای مدیر
            </label>
            <input id="file-upload" type="file" />
          </div>
          <div class="file-box btn-secondary">
            <i class="fa fa-upload" aria-hidden="true"></i>
            <label for="file-upload" class="custom-file-upload">
              تصویر مدرک تحصیلی برای مدیر
            </label>
            <input id="file-upload" type="file" />
          </div>
          <div class="file-box btn-secondary">
            <i class="fa fa-upload" aria-hidden="true"></i>
            <label for="file-upload" class="custom-file-upload">
              فایل رزومه برای کارفرما
            </label>
            <input id="file-upload" type="file" />
          </div>
        </div>
      </CCol>
      <CCol col="9"
        ><div class="main-card mt-2">
          <textarea
            class="w-100"
            rows="8"
            placeholder="توضیحاتی مربوط به خودتان برای نمایش به کارفرما را می توانید در این بخش بنویسید (این توضیحات برای همکاری شما با کارفرما تاثیر مثبت دارد)"
          ></textarea></div
      ></CCol>
    </CRow>
    <CRow class="w-100 mt-2">
      <CCol col="6">
        <div class="main-card">
          <h5 class="main-card-title">
            تخصص های خود را از لیست زیر انتخاب کنید (می توانید چند تخصص انتخاب
            کنید)
          </h5>
          <input
            type="text"
            class="main-card-search"
            placeholder="جستجوی تخصص ..."
          />
          <div class="expertise-box">
            <label class="expertise-box-item style-h">
              <input type="checkbox" />
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__body">
                <h5>عنوان تخصص</h5>
                <h6>
                  توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات
                  تخصص
                </h6>
              </div>
            </label>
            <label class="expertise-box-item style-h">
              <input type="checkbox" />
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__body">
                <h5>عنوان تخصص</h5>
                <h6>
                  توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات
                  تخصص
                </h6>
              </div>
            </label>
            <label class="expertise-box-item style-h">
              <input type="checkbox" />
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__body">
                <h5>عنوان تخصص</h5>
                <h6>
                  توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات
                  تخصص
                </h6>
              </div>
            </label>
            <label class="expertise-box-item style-h">
              <input type="checkbox" />
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__body">
                <h5>عنوان تخصص</h5>
                <h6>
                  توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات
                  تخصص
                </h6>
              </div>
            </label>
            <label class="expertise-box-item style-h">
              <input type="checkbox" />
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__body">
                <h5>عنوان تخصص</h5>
                <h6>
                  توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات
                  تخصص
                </h6>
              </div>
            </label>
            <label class="expertise-box-item style-h">
              <input type="checkbox" />
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__body">
                <h5>عنوان تخصص</h5>
                <h6>
                  توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات
                  تخصص
                </h6>
              </div>
            </label>
            <label class="expertise-box-item style-h">
              <input type="checkbox" />
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__body">
                <h5>عنوان تخصص</h5>
                <h6>
                  توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات
                  تخصص
                </h6>
              </div>
            </label>
            <label class="expertise-box-item style-h">
              <input type="checkbox" />
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__body">
                <h5>عنوان تخصص</h5>
                <h6>
                  توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات تخصص توضیحات
                  تخصص
                </h6>
              </div>
            </label>
          </div>
        </div>
      </CCol>
      <CCol col="6">
        <div class="main-card">
          <h5 class="main-card-title">
            محدوده فعالیت خود را روی نقشه انتخاب کنید
          </h5>
          <div class="map-box">
            <div id="map" ref="map" />
            <geocode-search
              @selectedGeocode="geocodeFind"
              style="right: 10vw; top: 1vh"
            ></geocode-search>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MainBodyHeader from "../../components/mainHeader.vue";
import { getCurrentUser } from "../../utils/index";
import { apiUrlRoot } from "../../constants/config";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css"; 
import GeocodeSearch from "../../components/map/GeocodeSearch.vue";

export default {
  name: "userExpertiseForm",
  components: {
    MainBodyHeader,
    GeocodeSearch,
  },
  data() {
    return {
      loading: false,
      imageText: {
        DegreeOfEducation: "تصویر آخرین مدرک تحصیلی را بارگذاری کنید",
        BirthCertificate: "تصویر صفحه اول شناسنامه را بارگذاری کنید",
        NationalCard: "تصویر کارت ملی را بارگذاری کنید",
        Resume: "فایل رزومه خود را بارگذاری کنید",
      },
      map: null,
      expertiseList: [],
      DegreeOfEducationFile: null,
      BirthCertificateFile: null,
      NationalCardFile: null,
      ResumeFile: null,
      DegreeOfEducationUrl: "",
      BirthCertificateUrl: "",
      NationalCardUrl: "",
      ResumeUrl: "",
      WorkExperience: null,
      Text: "",
      advicerId: 0,
      farmuserId: 0,
      userExpertiseSelected: [],
    };
  },

  methods: {
    ...mapActions("dashboard", [
      "GetAllExpertise",
      "GetAdvicerExpertiseProfile",
      "SaveAdvicerExpertiseProfile",
      "GetFarmUserExpertiseProfile",
      "SaveFarmUserExpertiseProfile",
      "logout",
    ]),
    async fetchData(usertype) {
      switch (usertype) {
        case "FarmUser": {
          this.loading = true;
          let resultFarmUser = await this.GetFarmUserExpertiseProfile({});
          console.log("resultFarmUser", resultFarmUser);
          this.loading = false;
          this.Text = resultFarmUser.text;
          this.farmuserId = resultFarmUser.farmUserId;
          this.WorkExperience = resultFarmUser.workExperience;
          this.DegreeOfEducationUrl = resultFarmUser.degreeOfEducation
            ? `${apiUrlRoot}/Expertise/${resultFarmUser.degreeOfEducation}`
            : null;
          this.imageText.DegreeOfEducation = resultFarmUser.degreeOfEducation
            ? "ویرایش فایل آخرین مدرک تحصیلی ..."
            : this.imageText.DegreeOfEducation;

          this.BirthCertificateUrl = resultFarmUser.birthCertificate
            ? `${apiUrlRoot}/Expertise/${resultFarmUser.birthCertificate}`
            : null;
          this.imageText.BirthCertificate = resultFarmUser.birthCertificate
            ? "ویرایش فایل صفحه اول شناسنامه ..."
            : this.imageText.BirthCertificate;

          this.NationalCardUrl = resultFarmUser.nationalCard
            ? `${apiUrlRoot}/Expertise/${resultFarmUser.nationalCard}`
            : null;
          this.imageText.NationalCard = resultFarmUser.nationalCard
            ? "ویرایش فایل کارت ملی ..."
            : this.imageText.NationalCard;

          this.ResumeUrl = resultFarmUser.resume
            ? `${apiUrlRoot}/Expertise/${resultFarmUser.resume}`
            : null;
          this.imageText.Resume =
            resultFarmUser.resume != null
              ? "ویرایش فایل رزومه  ..."
              : this.imageText.Resume;

          this.userExpertiseSelected = resultFarmUser.expertise.map((x) => {
            return { id: x.item2, name: x.item1 };
          });
          break;
        }
        case "Advicer": {
          this.loading = true;
          let resultAdvicer = await this.GetAdvicerExpertiseProfile({});
          this.loading = false;
          this.Text = resultAdvicer.text;
          this.advicerId = resultAdvicer.advicerId;
          this.WorkExperience = resultAdvicer.workExperience;
          this.DegreeOfEducationUrl = resultAdvicer.degreeOfEducation
            ? `${apiUrlRoot}/Expertise/${resultAdvicer.degreeOfEducation}`
            : null;
          this.imageText.DegreeOfEducation = resultAdvicer.degreeOfEducation
            ? "ویرایش فایل آخرین مدرک تحصیلی ..."
            : this.imageText.DegreeOfEducation;

          this.BirthCertificateUrl = resultAdvicer.birthCertificate
            ? `${apiUrlRoot}/Expertise/${resultAdvicer.birthCertificate}`
            : null;
          this.imageText.BirthCertificate = resultAdvicer.birthCertificate
            ? "ویرایش فایل صفحه اول شناسنامه ..."
            : this.imageText.BirthCertificate;

          this.NationalCardUrl = resultAdvicer.nationalCard
            ? `${apiUrlRoot}/Expertise/${resultAdvicer.nationalCard}`
            : null;
          this.imageText.NationalCard = resultAdvicer.nationalCard
            ? "ویرایش فایل کارت ملی ..."
            : this.imageText.NationalCard;

          this.ResumeUrl = resultAdvicer.resume
            ? `${apiUrlRoot}/Expertise/${resultAdvicer.resume}`
            : null;
          this.imageText.Resume =
            resultAdvicer.resume != null
              ? "ویرایش فایل رزومه  ..."
              : this.imageText.Resume;

          this.userExpertiseSelected = resultAdvicer.expertise.map((x) => {
            return { id: x.item2, name: x.item1 };
          });
          this.userExpertiseSelected = resultAdvicer.expertise.map((x) => {
            return { id: x.item2, name: x.item1 };
          });
          break;
        }
        default:
          this.$notify({
            title: "پیام سیستم",
            text: "نوع کاربری شما معتبر نمی باشد<br /><br />",
            type: "error",
          });
          break;
      }
    },
    async getExpertiseList() {
      this.loading = true;
      let result = await this.GetAllExpertise({});
      this.loading = false;
      if (result) {
        this.expertiseList = result.map((item) => {
          return {
            id: item.id,
            name: item.name,
            label: item.label,
          };
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "امکان تکمیل حساب کاربری نیست با پشتیبانی تماس بگیرید<br /><br />",
          type: "error",
        });
      }
    },
    onFileChangeFile(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      switch (e.target.name) {
        case "DegreeOfEducation":
          this.DegreeOfEducationFile = files[0];
          this.imageText.DegreeOfEducation = this.DegreeOfEducationFile.name;
          break;
        case "BirthCertificate":
          this.BirthCertificateFile = files[0];
          this.imageText.BirthCertificate = this.BirthCertificateFile.name;
          break;
        case "NationalCard":
          this.NationalCardFile = files[0];
          this.imageText.NationalCard = this.NationalCardFile.name;
          break;
        case "Resume":
          this.ResumeFile = files[0];
          this.imageText.Resume = this.ResumeFile.name;
          break;
        default:
          return;
      }
    },
    async submit() {
      let formData = new FormData();
      formData.append("DegreeOfEducation", this.DegreeOfEducationFile);
      formData.append("BirthCertificate", this.BirthCertificateFile);
      formData.append("NationalCard", this.NationalCardFile);
      formData.append("Resume", this.ResumeFile);
      formData.append("WorkExperience", this.WorkExperience);
      formData.append("Text", this.Text);
      let ExpertisesId = this.userExpertiseSelected.map((x) => {
        return x.id;
      });
      formData.append("ExpertisesId", ExpertisesId);
      let result = null;
      if (getCurrentUser().type == "FarmUser") {
        formData.append("FarmUserId", this.farmuserId);
        result = await this.SaveFarmUserExpertiseProfile({
          expertiseinfo: formData,
        });
      } else {
        formData.append("AdvicerId", this.advicerId);
        result = await this.SaveAdvicerExpertiseProfile({
          expertiseinfo: formData,
        });
      }
      if (result.succeeded) {
        this.$notify({
          title: "پیام سیستم",
          text: "حساب کاربری شما به روز شد پس از تایید مدیر فعال میشود <br /><br />",
          type: "success",
        });
        this.logout();
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در ثبت اطلاعات ...<br /><br />",
          type: "error",
        });
      }
    },
    addExpertiseToUser(item) {
      if (this.userExpertiseSelected.some((x) => x.id == item.id)) {
        this.$delete(
          this.userExpertiseSelected,
          this.userExpertiseSelected.findIndex((x) => x.id == item.id)
        );
      } else {
        this.userExpertiseSelected.push({
          id: item.id,
          name: item.name,
        });
      }
    },

    geocodeFind(coords) {
      this.map.fitBounds([
        [coords[2], coords[0]], // southwestern corner of the bounds
        [coords[3], coords[1]], // northeastern corner of the bounds
      ]);
    },
  },
  mounted() {
    this.getExpertiseList();
    this.fetchData(getCurrentUser().type);

    mapboxgl.accessToken =
      "pk.eyJ1IjoiaGFtaWRrYXplbXphZGVoIiwiYSI6ImNrcTJnNmY4NTAzczMybm85bDNvOGJ4NTYifQ.uEM3b50GGJ0aCbsXL3-O7A";
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Lazy load the plugin
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/hamidkazemzadeh/ckq2sozke25hf17qh16mdszbh",
      center: [53.12609431323281, 33.59332186046887],
      zoom: 4,
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
        zoom: true,
      },
      defaultMode: "draw_polygon",
    });
    this.map.on("load", function () {});
  },
};
</script>

<style scoped>
.h-45 {
  height: 45% !important;
}
.main-card-search[type="text"] {
  width: 80%;
  margin-right: 10%;
  border: none;
  border-bottom: 1px solid #455a64;
  margin-bottom: 4px;
  padding-right: 10px;
  outline: none;
}
.main-card-title {
  font-size: 1em;
  margin: 6px 0px;
  text-align: right;
  color: #1b5e20;
  font-weight: bold;
}
textarea {
  resize: none;
  outline: none;
  padding: 4px;
}
.btn-action {
  border: none;
  background-color: #2e7d32;
  padding: 0.3em 1em;
  color: #fff;
  border-radius: 14px;
}

.file-box {
  display: flex;
  height: 4vh;
  align-items: center;
  justify-content: center;
}
input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 0px 12px;
  cursor: pointer;
  margin: 0px;
}

.number-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: -1px 2px 6px -3px #000;
  border-radius: 7px;
  border-right: 4px solid #e91e63;
}
.number-box h4 {
  flex-grow: 3;
  font-size: 1.2em;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}
.number-box input[type="number"] {
  flex-grow: 2;
  width: 2vw;
  margin: 0px 12px;
  border: none;
  border-bottom: 1px solid #607d8b;
  text-align: center;
  outline: none;
}
.number-box i {
  flex-grow: 1;
  font-size: 2em;
  color: #607d8b;
}

.map-box {
  width: 100%;
  height: 50vh;
  position: relative;
}

#map {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.expertise-box {
  width: 100%;
  height: 47vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
}
.expertise-box-item.style-h {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.expertise-box-item.style-h input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.expertise-box-item.style-h input:checked ~ .checkbox__checkmark {
  background-color: #C8E6C9;
}
.expertise-box-item.style-h input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
  top: -3px;
}
.expertise-box-item.style-h input:checked ~ .checkbox__body {
  background-position: 0 0;
  color: #fff;
}
.expertise-box-item.style-h:hover input ~ .checkbox__checkmark {
  background-color: #C8E6C9;
}
.expertise-box-item.style-h:hover input:checked ~ .checkbox__checkmark {
  background-color: #C8E6C9;
}
.expertise-box-item.style-h:hover .checkbox__body {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
.expertise-box-item.style-h .checkbox__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  height: 22px;
  width: 22px;
  background-color: #C8E6C9;
  transition: background-color 0.25s ease;
  border-radius: 4px;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1),
    inset -2px -2px 4px rgba(255, 255, 255, 0.4);
}
.expertise-box-item.style-h .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 10px;
  top: -15px;
  width: 10px;
  height: 20px;
  border: solid #388E3C;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease, top 0.25s ease;
}
.expertise-box-item.style-h .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
  transition: font-weight 0.25s ease;
  font-weight: bold;
  color: #000;
  background: linear-gradient(
    45deg,
    #cddc39 0%,
    #7cb342 50%,
    #7cb342 50%,
    #558b2f 100%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  padding: 15px;
  padding-left: 52px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s ease, background 1s ease, color 0.25s ease;
}
</style>